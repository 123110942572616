import React, { useEffect, useState } from 'react';
import {
  Container,
  Content,
  EmptyDiv,
  Header,
  IconWrapper,
  MethodsContainer,
  NoteText,
  StyledButton,
} from './WithdrawPage.styles';
import { Text16Bold, Text20Bold } from '../../components/utils/Text/Text.styles';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron_left.svg';
import { NumericInput, WithdrawalMethod } from '../../components';
import { useNavigate } from 'react-router-dom';
import { WALLET } from '../../components/App/routes';
import { useDispatch, useSelector } from 'react-redux';
import { VALUE_OF_ONE_STAR_IN_USD, WITHDRAWAL_METHOD_TYPE } from '../../utils/constants';
import { selectWithdrawalActionsPending } from '../../store/slices/withdrawal/slice';
import { requestWithdrawal } from '../../store/slices/withdrawal/asyncThunks';
import { notifyError } from '../../utils/notify';
import { selectUser } from '../../store/slices/user/slice';
import { useConfirmationModal } from '../../components/modals/ConfirmationModal/ConfirmationModal';

const WithdrawPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { earnings } = user.analytics;
  const { requestWithdrawal: requestWithdrawalPending } = useSelector(selectWithdrawalActionsPending);
  const [selectedMethodType, setSelectedMethodType] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [amount, setAmount] = useState('');

  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();

  useEffect(() => {
    if (
      earnings.available * VALUE_OF_ONE_STAR_IN_USD < 500 ||
      user.thisMonthWithdrawals >= 2 ||
      user.hasPendingWithdrawal
    ) {
      navigate(`/${WALLET}`);
    }
  }, [user, earnings.available, navigate]);

  const onMethodClick = type => {
    if (type === selectedMethodType) {
      setSelectedMethodType(null);
      setSelectedMethod(null);
    } else {
      setSelectedMethodType(type);
    }
  };

  useEffect(() => {
    setConfirmationModalLoader(requestWithdrawalPending);
  }, [requestWithdrawalPending, setConfirmationModalLoader]);

  const submitWithdrawalRequest = () => {
    openModal(
      'Request Withdrawal',
      `Kindly review your banking details for accuracy before submitting.\nPlease note, we are unable to reverse payments sent to incorrect accounts.`,
      'Confirm',
      () => {
        dispatch(
          requestWithdrawal({ withdrawalMethodType: selectedMethodType, withdrawalMethodId: selectedMethod, amount }),
        )
          .unwrap()
          .then(() => {
            closeModal();
            navigate(`/${WALLET}`);
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Cancel',
      () => {
        closeModal();
      },
    );
  };

  return (
    <Container>
      <Content>
        <Header>
          <IconWrapper onClick={() => navigate(`/${WALLET}`)}>
            <ChevronLeftIcon />
          </IconWrapper>
          <Text20Bold>Withdraw Money</Text20Bold>
          <EmptyDiv />
        </Header>
        <NoteText>
          Your balance can be withdrawn 21 days after it’s earned. You can request up to two withdrawals every month.
        </NoteText>
        <Text16Bold>Select Method</Text16Bold>
        <MethodsContainer>
          <WithdrawalMethod
            title="Domestic Wire"
            description="Withdraw to a US bank account."
            note="Arriving in 5-7 business days."
            type={WITHDRAWAL_METHOD_TYPE.DOMESTIC_WIRE}
            isSelected={selectedMethodType === WITHDRAWAL_METHOD_TYPE.DOMESTIC_WIRE}
            onClick={() => onMethodClick(WITHDRAWAL_METHOD_TYPE.DOMESTIC_WIRE)}
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
          />
          <WithdrawalMethod
            title="International Wire"
            description="Withdraw to an international (non US) bank account."
            note="Arriving in 5-7 business days."
            isSelected={selectedMethodType === WITHDRAWAL_METHOD_TYPE.INTERNATIONAL_WIRE}
            type={WITHDRAWAL_METHOD_TYPE.INTERNATIONAL_WIRE}
            onClick={() => onMethodClick(WITHDRAWAL_METHOD_TYPE.INTERNATIONAL_WIRE)}
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
          />
          <WithdrawalMethod
            title="Crypto"
            description="Withdraw in cryptocurrency."
            note="Arriving in 1-2 business days."
            isSelected={selectedMethodType === WITHDRAWAL_METHOD_TYPE.CRYPTO}
            type={WITHDRAWAL_METHOD_TYPE.CRYPTO}
            onClick={() => onMethodClick(WITHDRAWAL_METHOD_TYPE.CRYPTO)}
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
          />
        </MethodsContainer>
        <NumericInput label="Amount" placeholder="Enter amount" onChange={e => setAmount(e.target.value)} />
        <StyledButton
          title="Submit"
          disabled={!selectedMethod || !amount.trim()}
          onClick={submitWithdrawalRequest}
          isLoading={requestWithdrawalPending}
        />
      </Content>
    </Container>
  );
};

export default WithdrawPage;
